<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_MODERATION"
    title="Publication Moderation"
    :breadcrumbs="breadcrumbs"
    page-name="Moderation"
  >
    <iSelect
      :model-value="commentModerationStrategy"
      return-value
      :items="validCommentModerationStrategyTypes"
      label="Comment Moderation"
      :disabled="!canCommentOnPosts"
      value-field="value"
      @update:model-value="updateCommentModerationConfig"
    />
    <!-- TODO: disable if users cannot comment on posts (pending VCL update to ISelect)-->
    <iSelect
      :model-value="ugcModerationStrategy"
      return-value
      :items="validUgcModerationStrategyTypes"
      label="User Generated Content Moderation"
      :disabled="!canUserCreatePost"
      value-field="value"
      @update:model-value="updateCommentModerationConfig"
    />
    <!-- disable if users cannot create posts (pending VCL update to ISelect)-->
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_MODERATION,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { useConfigurationStore } from "@/stores/configuration-store";
import { mapActions, mapState } from "pinia";
export default {
  name: "PublicationModeration",
  components: {
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_MODERATION,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(useConfigurationStore, [
      "canUserCreatePost",
      "canCommentOnPosts",
      "ugcModerationStrategy",
      "commentModerationStrategy",
    ]),
    validCommentModerationStrategyTypes() {
      return [{ name: "None", value: "none" }];
    },
    validUgcModerationStrategyTypes() {
      return [{ name: "None", value: "none" }];
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
        {
          url: `/publication/${this.publicationId}/user-management`,
          label: "User Management",
        },
      ];
    },
  },
  methods: {
    ...mapActions(useConfigurationStore, ["updateConfiguration"]),
    async updateUgcModerationConfig(moderationStrategy) {
      if (moderationStrategy !== this.ugcModerationStrategy) {
        this.updateConfiguration({ ugcModerationStrategy: moderationStrategy });
      }
    },
    async updateCommentModerationConfig(moderationStrategy) {
      if (moderationStrategy !== this.commentModerationStrategy) {
        this.updateConfiguration({
          commentModerationStrategy: moderationStrategy,
        });
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_THEMES"
    title="Edit Theme"
    :breadcrumbs="breadcrumbs"
    page-name="Edit Theme"
  >
    <iColumn>
      <ThemeConfiguration
        v-model="innerTheme"
        :palette="palette"
        edit-mode
        @has-errors="formHasErrors = $event"
      />

      <iSpace :height="10" />
      <iText v-if="formHasErrors" variant="error">
        Please correct the errors above before saving.
      </iText>
      <iText v-if="canDeployToProduction" variant="subtle">
        *Staged changes to the associated theme palette will also be published
        when you publish this theme.
      </iText>
      <iRow align="between" vertical-align="middle">
        <iRow width="hug">
          <iColumn width="hug">
            <iButton
              :is-loading="isLoadingPostTheme"
              loading-spinner-position="inside"
              :disabled="formHasErrors"
              variant="primary"
              @click="showStagingConfirmation = true"
            >
              Stage Changes
            </iButton>
          </iColumn>
          <iColumn width="hug">
            <iButton
              v-if="canDeployToProduction"
              :is-loading="isLoadingPostTheme"
              loading-spinner-position="inside"
              :disabled="formHasErrors"
              variant="primary"
              @click="showProductionConfirmation = true"
            >
              Publish
            </iButton>
          </iColumn>
          <iColumn width="hug">
            <iLink :href="`/publication/org/${orgId}/themes`">
              <iButton variant="secondary">
                Go Back
              </iButton>
            </iLink>
          </iColumn>
        </iRow>
      </iRow>
    </iColumn>
    <iPromptModal
      :visible="showStagingConfirmation"
      title="Stage Changes"
      :message="`What changes did you make to your theme ?`"
      label="Message/Memo"
      @close="showStagingConfirmation = false"
      @submit="deployThemeToStaging({...innerTheme, memo: $event})"
    />
    <iPromptModal
      :visible="showProductionConfirmation"
      title="Publish Changes"
      :message="`What changes did you make to your theme ?`"
      label="Message/Memo"
      @close="showProductionConfirmation = false"
      @submit="deployThemeToProduction({...innerTheme, memo: $event})"
    />
    <iModal
      :visible="showRollbackWarning"
      title="Are you sure?"
      :width="800"
      :height="600"
      :close-on-esc="true"
      :close-on-click-outside="true"
      @click:primary="rollbackTheme(themeInfo.production_version_id)"
      @click:secondary="showRollbackWarning = false"
      @close="closeRollbackWarning = false"
    >
      <template #body>
        <iText>
          You are about to rollback the theme to its production values, this
          action can not be undone. Are you sure you want to proceed?
        </iText>
      </template>
    </iModal>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import ThemeConfiguration from "@/components/ThemeConfiguration";
import { PAGE_THEMES, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import { useGeneralStore } from "@/stores/general-store";
import { useThemesStore } from "@/stores/themes-store";
import { mapActions, mapState } from "pinia";

export default {
  name: "ThemesEdit",
  components: { ThemeConfiguration, PageWrapper },
  props: {
    theme: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    themeInfo: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    palette: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_THEMES,
      innerTheme: this.theme,
      formHasErrors: false,
      showStagingConfirmation: false,
      showProductionConfirmation: false,
      showRollbackWarning: false,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["orgId"]),
    ...mapState(useThemesStore, ["isLoadingPostTheme"]),
    canDeployToProduction() {
      return (
        this.themeInfo.production_version_id !==
        this.themeInfo.staging_version_id
      );
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/org/${this.orgId}/themes`, label: "Themes" },
      ];
    },
  },
  methods: {
    ...mapActions(useThemesStore, [
      "deployThemeToStaging",
      "deployThemeToProduction",
      "rollbackTheme",
    ]),
  },
};
</script>

<style scoped lang="scss"></style>

<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_FEATURES"
    title="Publication Features"
    :breadcrumbs="breadcrumbs"
    page-name="Features"
    align="left"
    width="full"
  >
    <iColumn gap="large">
      <iRow>
        <iColumn>
          <iSubHeading>Enabled</iSubHeading>
          <iRow
            v-for="(enabledConfig, enabledIndex) in enabledConfigurations"
            :key="enabledIndex"
            gap="standard"
            vertical-align="middle"
          >
            <iText variant="subtle">
              {{ enabledConfig }}
            </iText>
          </iRow>
        </iColumn>
      </iRow>

      <iRow>
        <iColumn>
          <iSubHeading>Disabled</iSubHeading>
          <iRow
            v-for="(disabledConfig, disabledIndex) in disabledConfigurations"
            :key="disabledIndex"
            gap="standard"
            vertical-align="middle"
          >
            <iText variant="subtle">
              {{ disabledConfig }}
            </iText>
          </iRow>
        </iColumn>
      </iRow>
    </iColumn>
  </PageWrapper>
</template>

<script>
import { mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_FEATURES,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { useConfigurationStore } from "@/stores/configuration-store";
export default {
  name: "PublicationFeatures",
  components: {
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_FEATURES,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(useConfigurationStore, ["$_configuration"]),
    ...mapState(usePublicationStore, ["orgId", "publicationId"]),
    configurationSummary() {
      // eslint-disable-next-line no-unused-vars
      let { version: _, ...config } = this.$_configuration;
      return config;
    },
    enabledConfigurations() {
      return Object.entries( this.configurationSummary).filter(entry => entry[1].enabled).map(config => config[0]);
    },
    disabledConfigurations() {
      return Object.entries( this.configurationSummary).filter(entry => !entry[1].enabled).map(config => config[0]);
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
      ];
    },
  },
};
</script>

<style scoped>
</style>

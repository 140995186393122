import CreatorSmartFeedEngine from "@/classes/creator-smart-feed-engine";
import { useGeneralStore } from "@/stores/general-store";
import { defineSmartFeedPiniaStore } from "@bloglovin/vue-framework";

export const useOrgApplicationFeedStore = defineSmartFeedPiniaStore(
  "org-application-feed-store",
  {
    state: () => ({
      entityType: CreatorSmartFeedEngine.ORG_APPLICATION,
      feed: [],
      metadata: {
        hasMore: true,
        lastId: 0,
        orgId: useGeneralStore().orgId,
      },
      isLoading: false,
    }),
    getters: {
      hasMore() {
        return this.metadata.hasMore;
      },
    },
    actions: {
      getSmartFeed() {
        this.apiGet(
          `/org/${this.metadata.orgId}/applications/smart-feed`,
          {
            last_id: this.metadata.lastId,
          },
          data => this.handleSmartFeedResponse(data, CreatorSmartFeedEngine),
        );
      },
    },
  },
);

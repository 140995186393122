<template>
  <PublicationCollaboratorCard
    :collaborator="mapEntityToCollaborator(entity)"
    show-actions
    @revoke="handleRevokeOrgApplication"
    @accept="handleAcceptOrgCollaboratorApplication"
    @deny="handleDeclineOrgCollaboratorApplication"
  />
</template>

<script>
import PublicationCollaboratorCard from "@/components/publication/PublicationCollaboratorsCard.vue";
import { useCollaborationStore } from "@/stores/collaboration-store";

export default {
  name: "PublicationCollaboratorCardSmall",
  components: {
    PublicationCollaboratorCard,
  },
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  methods: {
    mapEntityToCollaborator(entity) {
      return {
        id: entity.applicationId,
        name: entity.publicationName,
        role: "Applicant",
        status: entity.status,
        email: entity.email,
        avatar: entity.publicationLogo,
        orgId: entity.orgId,
      };
    },
    handleAcceptOrgCollaboratorApplication() {
      useCollaborationStore().acceptOrgCollaboratorApplication(
        this.entity.publicationId,
        this.entity.orgId,
        this.entity.applicationId,
      );
    },
    handleDeclineOrgCollaboratorApplication() {
      useCollaborationStore().declineOrgCollaboratorApplication(
        this.entity.publicationId,
        this.entity.orgId,
        this.entity.applicationId,
      );
    },
    handleRevokeOrgApplication() {
      useCollaborationStore().revokeOrgCollaboratorApplication(
        this.entity.publicationId,
        this.entity.orgId,
        this.entity.applicationId,
      );
    },
  },
};
</script>

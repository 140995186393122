<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="publication.name"
    :breadcrumbs="publicationBreadCrumbs"
    page-name="Overview"
    align="left"
    width="full"
  >
    <ProfileWrapper
      :splash-image="publicationSplashImage"
      :logo-image="publicationLogo"
      :is-loading-splash="loadingUploadSplashImage"
      can-upload-logo
      can-upload-splash
      can-edit-title
      :is-loading-logo="loadingUploadPublicationLogo"
      :title="publicationName"
      @click:save-title="savePublicationName"
      @click:splash-image="triggerUploadSplash"
      @click:logo-image="triggerUploadLogo"
    >
      <template #details>
        <iText>{{ totalUsers }} {{ totalUsers === 1 ? 'user' : 'users' }}</iText>
        <iText>{{ totalSubscribers }} {{ totalSubscribers === 1 ? 'subscriber' : 'subscribers' }}</iText>
      </template>
      <iSpace :height="1" />
      <iStyledCard title="Description">
        <template #description>
          <iTextEdit
            :width="400"
            max-width-only
            :value="publicationDescription"
            text-area
            show-as-text
            variant="locked"
            @submit="savePublicationDescription"
          >
            <template #display>
              <iRow vertical-align="middle" wrap="nowrap">
                <iText v-if="!!publicationDescription">
                  {{ publicationDescription }}
                </iText>
                <iText v-else variant="subtle">
                  Click here to add a description for your publication
                </iText>
                <iColumn width="hug">
                  <iIcon icon="pencil" />
                </iColumn>
              </iRow>
            </template>
          </iTextEdit>
        </template>
      </iStyledCard>
      <iStyledCard :subtitle="'Status: ' + mappedPublicationStatus" title="Domain & Links">
        <template #description>
          <iColumn>
            <iText font-weight="dark">
              Public Profile:
            </iText>
            <iLink :href="publicProfileUrl">
              {{ publicProfileUrl }}
            </iLink>
            <iText font-weight="dark">
              Production:
            </iText>
            <iLink v-if="hasDomain" target="_blank" :href="domainUrl">
              {{ domainUrl }}
            </iLink>
            <iLink v-if="!hasDomain" target="_blank" :href="internalDomain">
              {{ internalDomain }}
            </iLink>
            <iLink
              v-if="hasDomain"
              color="subtle"
              target="_blank"
              :href="internalDomain"
            >
              {{ internalDomain }}
            </iLink>
            <iText font-weight="dark">
              Staging:
            </iText>
            <iLink
              v-if="hasDomain"
              target="_blank"
              :href="domainStaging"
            >
              {{ domainStaging }}
            </iLink>
            <iLink
              v-if="!hasDomain"
              target="_blank"
              :href="internalStagingDomain"
            >
              {{ internalStagingDomain }}
            </iLink>
            <iLink
              v-if="hasDomain"
              color="subtle"
              target="_blank"
              :href="internalStagingDomain"
            >
              {{ internalStagingDomain }}
            </iLink>
          </iColumn>
        </template>
      </iStyledCard>
    </ProfileWrapper>
    <iUploader ref="logo-uploader" @upload-file="uploadLogo" />
    <iUploader ref="splash-uploader" @upload-file="uploadSplash" />
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_DASHBOARD,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import ProfileWrapper from "@/components/publication/ProfileWrapper";

export default {
  name: "ViewPublicationDetails",
  components: {
    ProfileWrapper,
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_DASHBOARD,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(usePublicationStore, [
      "publication",
      "publicationId",
      "orgId",
      "publicationLogo",
      "publicationSplashImage",
      "publicationName",
      "publicationDescription",
      "isLoadingSavePublicationName",
      "isLoadingSavePublicationDescription",
      "loadingUploadPublicationLogo",
      "loadingUploadSplashImage",
    ]),
    domain() {
      return this.publication.domain;
    },
    publicationBreadCrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
      ];
    },
    totalUsers() {
      return this.publication.metrics.filter(metric => metric.id === "users")?.[0]?.value || 0;
    },
    mappedPublicationStatus() {
      switch (this.publication.status) {
        case "active":
          return "Live";
        case "draft":
          return "Under Construction";
        default:
          return "Offline";
      }
    },
    totalSubscribers() {
      return this.publication.metrics.filter(metric => metric.id === "subscribers")?.[0]?.value || 0;
    },
    domainStaging() {
      if (this.domain) {
        if (this.domain.startsWith("http", 0)) {
          return this.domain;
        }
        return "https://" + this.domain + "/staging";
      }
      return "No domain set";
    },
    domainUrl() {
      if (this.domain) {
        if (this.domain.startsWith("http", 0)) {
          return this.domain;
        }
        return "https://" + this.domain;
      }
      return "No domain set";
    },
    internalDomain() {
      return `${window.location.origin}/my-publication/${this.publicationId}`;
    },
    internalStagingDomain() {
      return `${window.location.origin}/my-publication/staging/${this.publicationId}`;
    },
    publicProfileUrl() {
      return `${window.location.origin}/community/publications/${this.publicationId}/profile`;
    },
    hasDomain() {
      return this.internalDomain !== this.domain;
    },
  },
  methods: {
    ...mapActions(usePublicationStore, ["uploadPublicationLogo", "uploadSplashImage", "savePublicationName", "savePublicationDescription"]),
    triggerEditPublicationName() {
      this.isEditNameOpen = true;
    },
    triggerEditPublicationDescription() {
      this.isEditNameOpen = true;
    },
    triggerUploadLogo() {
      this.$refs["logo-uploader"].$refs.file.click();
    },
    triggerUploadSplash() {
      this.$refs["splash-uploader"].$refs.file.click();
    },
    uploadLogo(file) {
      this.uploadPublicationLogo(file);
    },
    uploadSplash(file) {
      this.uploadSplashImage(file);
    },
  },
  styleGuide: () => ({
    logoBorderWidth: { "size.border": "thick" },
    logoBorderColor: { "color.border": "dark" },
    logoBackground: { "color.background": "light" },
  }),
};
</script>

<style scoped lang="scss">
.publication-header-wrapper{
  position: relative;
}

.publication-header {
  position: relative;
  bottom: 30px;
}

.publication-header-logo {
  border: v-bind("$getStyles.logoBorderWidth") solid v-bind("$getStyles.logoBorderColor");
  background: v-bind("$getStyles.logoBackground")
}
</style>

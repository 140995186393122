<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_FEATURE_SIGNUP_LOGIN"
    title="Configure Sign Up & Login"
    :breadcrumbs="breadcrumbs"
    page-name="Sign Up & Login"
    align="left"
    width="full"
  >
    <iRow gap="huge" wrap="wrap">
      <PublicationSettingsCard
        v-for="(settings, key) in signUpSettingsMap"
        :key="key"
        :description="settings.description"
        :title="settings.title"
        :features="settings.features"
        :focused="selectedSetting === settings"
        @select="selectSetting(key)"
      />
    </iRow>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import PublicationSettingsCard from "@/components/publication/PublicationLoginSettingsCard.vue";
import {
  PAGE_PUBLICATION_FEATURE_SIGNUP_LOGIN,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { useConfigurationStore } from "@/stores/configuration-store";
import { usePublicationStore } from "@/stores/publication-store";

import { mapActions, mapState } from "pinia";

export default {
  name: "PublicationSignupAndLogin",
  components: {
    PublicationSettingsCard,
    PageWrapper,
  },
  data() {
    return {
      PAGE_PUBLICATION_FEATURE_SIGNUP_LOGIN,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["orgId", "publicationId"]),
    ...mapState(useConfigurationStore, [
      "isSignupAndLoginEnabled",
      "canSignUp",
      "isPrivatePublication",
      "requireAccountApproval",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
        {
          url: `/publication/${this.publicationId}/user-management`,
          label: "User Management",
        },
      ];
    },
    selectedSetting() {
      return Object.values(this.signUpSettingsMap).find(setting => {
        let canSignUp = this.canSignUp === setting.features.canSignUp;
        let isPrivatePublication =
          this.isPrivatePublication === setting.features.isPrivatePublication;
        let requireAccountApproval =
          this.requireAccountApproval ===
          setting.features.requireAccountApproval;

        return canSignUp && isPrivatePublication && requireAccountApproval;
      });
    },
    signUpSettingsMap() {
      return {
        readOnlySite: {
          title: "Read Only Site",
          description: "No signup no users everything visible to the public",
          features: {
            isPrivatePublication: false,
            canSignUp: false,
            requireAccountApproval: false,
          },
        },
        signupOptional: {
          title: "Signup Optional",
          description:
            "All content visible to the public some features, like commenting, require signup",
          features: {
            isPrivatePublication: false,
            canSignUp: true,
            requireAccountApproval: false,
          },
        },
        signupRequired: {
          title: "Signup Required",
          description:
            "Anyone can signup but all content is hidden from the public",
          features: {
            isPrivatePublication: true,
            canSignUp: true,
            requireAccountApproval: false,
          },
        },
        inviteOnly: {
          title: "Invite Only",
          description:
            "Random signup not allowed, users must be invited then they signup and all content is hidden from the public",
          features: {
            isPrivatePublication: true,
            canSignUp: false,
            requireAccountApproval: true,
          },
        },
        applyToJoin: {
          title: "Apply to Join",
          description:
            "Strangers are allowed to signup, but admin approval required and all content is hidden from the public",
          features: {
            isPrivatePublication: true,
            canSignUp: true,
            requireAccountApproval: true,
          },
        },
      };
    },
  },
  methods: {
    ...mapActions(useConfigurationStore, ["updateConfiguration"]),
    selectSetting(settingKey) {
      let selectedSignupConfiguration = this.signUpSettingsMap[settingKey];
      this.updateConfiguration(selectedSignupConfiguration.features);
    },
  },
};
</script>

<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_CREATE_DIGITAL_PRODUCT"
    title="Create Digital Product"
    :breadcrumbs="breadcrumbs"
    page-name="Create Digital Product"
    align="left"
    width="full"
  >
    <iColumn>
      <iTextInput
        v-model="name"
        name="name"
        placeholder="Digital product name"
        label="Name: "
        :required="true"
        type="text"
      />
      <iTextInput
        v-model="description"
        name="description"
        placeholder="Best digital product..."
        label="Description: "
        :required="true"
        type="text"
        @blur="validateFormString"
      />
      <iUploader @upload-file="uploadDigitalProduct">
        <iButton>Change File</iButton>
      </iUploader>
      <iTextInput
        v-if="files.length < 1"
        v-model="digitalProductUrl"
        name="digital_product_url"
        placeholder="My first digital product"
        label="URL: "
        :required="true"
        type="text"
        @blur="validateFormString"
      />
      <iTextInput
        v-model="price"
        name="price"
        placeholder="23"
        label="Price: "
        :required="true"
        type="number"
        @blur="validateFormString"
      />
      <iSelect
        id="currency-code"
        v-model="currencyCode"
        :items="currencyCodes"
        label="Currency"
        value-field="key"
        text-field="symbol"
      />
      <iDatePicker v-model="startDateAvailable" label="Start Date: " />
      <iDatePicker v-model="endDateAvailable" label="End Date: " />
      <iButton :disabled="formHasError" type="button" @click="submitForm">
        Create Product
      </iButton>
      <iText v-if="formHasError" variant="error">
        {{ formError }}
      </iText>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import { useDigitalProductsStore } from "@/stores/digital-products-store";
import { validateStringInput } from "@bloglovin/vue-component-library";
import { SECTION_PUBLICATIONS, PAGE_CREATE_DIGITAL_PRODUCT } from "@/constants/nav-constants";
import { MAPPED_CURRENCY_CODES } from "@/constants/currency-constants";
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
export default {
  name: "CreateDigitalProduct",
  components: {
    PageWrapper,
  },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_CREATE_DIGITAL_PRODUCT,
      formError: null,
      name: "",
      description: "",
      digitalProductUrl: "",
      price: 0,
      currencyCode: MAPPED_CURRENCY_CODES[0],
      startDateAvailable: "",
      endDateAvailable: "",
      files: [],
    };
  },
  computed: {
    ...mapState(useDigitalProductsStore, ["publicationId", "orgId"]),
    ...mapState(usePublicationStore, ["publication"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        {
          url: `/publication/${this.publicationId}`,
          label: this.publication.name,
        },
        {
          url: `/publication/${this.publicationId}/digital-products`,
          label: "Digital Products",
        },
      ];
    },
    formHasError() {
      return (
        this.formError !== "" &&
        this.formError !== null &&
        this.formError !== undefined
      );
    },
    currencyCodes() {
      return MAPPED_CURRENCY_CODES;
    },
  },
  methods: {
    uploadDigitalProduct(e) {
      this.files = e;
    },
    ...mapActions(useDigitalProductsStore, {
      createDigitalProduct: "createDigitalProduct",
    }),
    validateFormString(e) {
      this.formError = validateStringInput(e.target.value);
    },
    submitForm() {
      this.createDigitalProduct(
        this.name,
        this.description,
        this.digitalProductUrl,
        this.price,
        this.currencyCode,
        this.startDateAvailable,
        this.endDateAvailable,
        this.files,
      );
    },
  },
};
</script>

<style scoped></style>

<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_CREATE_PUBLICATION"
    title="Create Publication"
    :breadcrumbs="publicationBreadCrumbs"
    page-name="Create Publication"
    align="left"
    width="full"
  >
    <iColumn>
      <iRow wrap="wrap" vertical-align="top" align="between">
        <iColumn width="hug">
          <iTextInput v-model="name" placeholder="Publication Name" :is-loading="isLoadingCreatePublication" />
          <iTextArea v-model="description" placeholder="Publication Description" :is-loading="isLoadingCreatePublication" />
        </iColumn>
        <iColumn width="hug">
          <iButton :disabled="!isValid" :is-loading="isLoadingCreatePublication" @click="submitPublication">
            Add publication
          </iButton>
        </iColumn>
      </iRow>
      <iRow>
        <iSubHeading>
          Select a starting template
        </iSubHeading>
      </iRow>
      <iRow
        vertical-align="top"
        wrap="wrap"
        height="hug"
        width="hug"
      >
        <iColumn
          v-for="(template, index) in templates"
          :key="index"
          :width="300"
          height="hug"
          @click="selectTemplate(index)"
        >
          <iOverlay :show-overlay-trigger="template.availability ==='available' ? 'none':'always'">
            <iStyledCard
              :focused="isSelected(template)"
              :splash-image="template.splashImage"
              :splash-image-max-height="200"
              :title="template.name"
              :description="template.description"
              badge="View Details"
              @click:badge="viewDetails($event, template)"
            />
            <template #overlay>
              <iColumn
                vertical-align="middle"
                align="center"
                width="fill"
                height="fill"
                class="template-card-overlay"
              >
                <iRow height="hug" width="hug">
                  <iTitle variant="subtle" class="overlay-text">
                    <template v-if="template.availability === 'pending'">
                      Coming Soon...
                    </template>
                    <template v-else>
                      Unavailable
                    </template>
                  </iTitle>
                </iRow>
              </iColumn>
            </template>
          </iOverlay>
        </iColumn>
      </iRow>
      <iRow>
        <TemplateDetails
          v-if="viewConfig.name"
          :visible="isDetailsOpen"
          :template="viewConfig.template"
          :title="viewConfig.name"
          @close-details="closeConfigDetails"
        />
      </iRow>
    </iColumn>
  </PageWrapper>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { usePublicationStore } from "@/stores/publication-store";
import PageWrapper from "@/components/PageWrapper";
import TemplateDetails from "@/components/TemplateDetails";
import { PAGE_CREATE_PUBLICATION, SECTION_PUBLICATIONS } from "@/constants/nav-constants";

export default {
  name: "CreatePublication",
  components: {
    PageWrapper,
    TemplateDetails,
  },
  data() {
    return {
      PAGE_CREATE_PUBLICATION,
      SECTION_PUBLICATIONS,
      name: "",
      description: "",
      theme: "0",
      selectedConfigIndex: "Blog",
      viewConfig: {},
      isDetailsOpen: false,
    };
  },
  styleGuide: () => ({
    templateCardPadding: { "size.spacing": "none" },
    templateCardDescriptionPadding: { "size.spacing": "standard" },
    templateCardBorderColor: { "color.border": "dark" },
    templateCardBorderWidth: { "size.border": "thin" },
    templateCardSelectedBackground: { "color.background": "striking" },
    templateCardBorderRadius: { "size.borderRadius": "large" },
    templateCardOverlayTextColor: { "color.font": "light" },
  }),
  computed: {
    ...mapState(usePublicationStore, ["isLoadingCreatePublication", "orgId", "templates"] ),
    selectedConfig() {
      return this.templates[this.selectedConfigIndex];
    },
    publicationBreadCrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
      ];
    },
    isValid() {
      return this.selectedConfig.name && this.selectedConfig.name !== "" && this.name !== "";
    },
  },
  methods: {
    ...mapActions(usePublicationStore, { createPublication: "createPublication" }),
    submitPublication() {
      this.createPublication( this.name, this.description, this.selectedConfig.template );
    },
    viewDetails($event, template) {
      $event.event.stopPropagation();
      this.viewConfig = template;
      this.openConfigDetails();
    },
    openConfigDetails() {
      this.isDetailsOpen = true;
    },
    closeConfigDetails() {
      this.isDetailsOpen = false;
    },
    isSelected(template) {
      return this.selectedConfig.name === template.name;
    },
    selectTemplate(index) {
      if (this.templates[index].availability === "available") {
        this.selectedConfigIndex = this.templates[index].name;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.template-card{
  padding:v-bind("$getStyles.templateCardPadding");
  border: v-bind("$getStyles.templateCardBorderWidth") solid v-bind("$getStyles.templateCardBorderColor");
  border-radius: v-bind("$getStyles.templateCardBorderRadius");
  overflow: hidden;
}
.template-card-description{
  padding:v-bind("$getStyles.templateCardDescriptionPadding");
}
.selected{
  background-color:v-bind("$getStyles.templateCardSelectedBackground");
}
.template-card-overlay{
  background-color: rgba(0, 0, 0, 25%);
  height: 100%;
  border-radius: v-bind("$getStyles.templateCardBorderRadius");
}
.overlay-text{
    color:v-bind("$getStyles.templateCardOverlayTextColor");
}
</style>

<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_USER_POSTING"
    title="User Posting"
    sub-title=""
    :breadcrumbs="breadcrumbs"
    page-name="User Posting"
    align="center"
    width="full"
  >
    <iColumn>
      <iSelect
        :model-value="ugcInterface"
        return-value
        :items="validUgcTypes"
        label="Post Creation Type"
        value-field="value"
        @update:model-value="updateUgcInterfaceConfiguration"
      />
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper.vue";
import {
  PAGE_PUBLICATION_USER_POSTING,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { useConfigurationStore } from "@/stores/configuration-store";
import { usePublicationStore } from "@/stores/publication-store";
import { mapActions, mapState } from "pinia";

export default {
  name: "PublicationUserPosting",
  components: { PageWrapper },
  data() {
    return {
      PAGE_PUBLICATION_USER_POSTING,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(usePublicationStore, [
      "orgId",
      "publication",
      "posts",
      "isLoading",
      "publicationId",
    ]),
    ...mapState(useConfigurationStore, ["ugcInterface"]),
    validUgcTypes() {
      return [
        { name: "None", value: "none" },
        { name: "Article", value: "article" },
        { name: "Multimedia", value: "multimedia" },
        { name: "Quick Post", value: "quickPost" },
      ];
    },
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        { url: `/publication/${this.publicationId}`, label: "Overview" },
        {
          url: `/publication/${this.publicationId}/user-management`,
          label: "User Management",
        },
      ];
    },
  },
  methods: {
    ...mapActions(useConfigurationStore, ["updateConfiguration"]),
    updateUgcInterfaceConfiguration(ugcType) {
      this.updateConfiguration({ ugcInterface: ugcType });
    },
  },
};
</script>

<style scoped lang="scss"></style>

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.heading[data-v-94a1d3e8] {
  margin-bottom: 0;
}
.posts[data-v-94a1d3e8] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/publication/ViewPublicationPostList.vue","webpack://./ViewPublicationPostList.vue"],"names":[],"mappings":"AAEA;EACE,gBAAA;ACDF;ADIA;EACE,aAAA;EACA,mBAAA;EACA,eAAA;EACA,SAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.heading {\n  margin-bottom: 0;\n}\n\n.posts {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  gap: 2rem;\n}\n",".heading {\n  margin-bottom: 0;\n}\n\n.posts {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  gap: 2rem;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_PUBLICATION_CHAT_ROOM"
    :title="`${publication.name} Chat Room`"
    :breadcrumbs="breadcrumbs"
    page-name="Chat Room"
  >
    <Conversation
      layout="chatroom"
      :entity-id="publicationId"
      context="publication_chatroom"
    />
  </PageWrapper>
</template>

<script>
import Conversation from "@/components/conversations/Conversation";
import PageWrapper from "@/components/PageWrapper";
import {
  PAGE_PUBLICATION_CHAT_ROOM,
  SECTION_PUBLICATIONS,
} from "@/constants/nav-constants";
import { usePublicationStore } from "@/stores/publication-store";
import { mapState } from "pinia";

export default {
  name: "PublicationChatRoom",
  components: {
    PageWrapper,
    Conversation,
  },
  data() {
    return {
      PAGE_PUBLICATION_CHAT_ROOM,
      SECTION_PUBLICATIONS,
    };
  },
  computed: {
    ...mapState(usePublicationStore, ["publication", "publicationId", "orgId"]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
        {
          url: `/publication/${this.publicationId}`,
          label: this.publication.name,
        },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
.page-wrapper {
  :deep(.primary-content) {
    overflow-y: scroll;
  }
}
</style>

<template>
  <PageWrapper
    :active-section="SECTION_PUBLICATIONS"
    :active-page="PAGE_THEMES"
    title="Themes"
    :breadcrumbs="breadcrumbs"
    page-name="Themes"
  >
    <iColumn width="fill" wrap="nowrap">
      <iButton @click="goToCreateThemes">
        Create Theme
      </iButton>
      <iSpace :height="10" />
      <iInfiniteScroll
        :height-offset="290"
        :has-more="hasMoreThemes"
        :is-loading="isLoadingThemes"
        @load-more="loadMore"
      >
        <iRow>
          <iStyledCard
            v-for="(theme, index) in themes"
            :key="index"
            :width="300"
            :title="theme.name"
            :subtitle="getChangesStatus(theme)"
            :default-href="`/publication/org/${orgId}/themes/${theme.theme_id}`"
            :tags="[
              {
                name: camelToTitleCase(theme.visibility || 'private'),
              },
              {
                name: getIsCloneText(theme),
              },
            ]"
          >
            <template #splash-container>
              <iRow gap="none">
                <div
                  v-for="(color, idx) in Object.keys(
                    theme.configuration.colors
                  ).filter((colorKey) => colorKey !== 'transparent')"
                  :key="idx"
                  :style="{
                    backgroundColor: theme.configuration.colors[color]['400'],
                    flex: 1,
                    height: '150px',
                    overflow: 'hidden',
                  }"
                />
              </iRow>
            </template>
            <template #footer>
              <iRow wrap="nowrap" vertical-align="middle" align="between">
                <iLink
                  width="fill"
                  :href="`/publication/org/${orgId}/themes/${theme.theme_id}/clone`"
                >
                  <iButton width="fill" variant="secondary">
                    Clone
                  </iButton>
                </iLink>
                <iLink
                  width="fill"
                  :href="`/publication/org/${orgId}/themes/${theme.theme_id}`"
                >
                  <iButton width="fill" variant="secondary">
                    Edit
                  </iButton>
                </iLink>
              </iRow>
            </template>
          </iStyledCard>
        </iRow>
      </iInfiniteScroll>
    </iColumn>
  </PageWrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";
import { PAGE_THEMES, SECTION_PUBLICATIONS } from "@/constants/nav-constants";
import { useGeneralStore } from "@/stores/general-store";
import { useThemesStore } from "@/stores/themes-store";
import { StatusEnum } from "@bloglovin/vue-component-library";
import { camelToTitleCase } from "@bloglovin/vue-framework";
import { mapActions, mapState, mapWritableState } from "pinia";

export default {
  name: "Themes",
  components: { PageWrapper },
  data() {
    return {
      SECTION_PUBLICATIONS,
      PAGE_THEMES,
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["orgId"]),
    ...mapWritableState(useThemesStore, [
      "themes",
      "isLoadingThemes",
      "hasMoreThemes",
    ]),
    breadcrumbs() {
      return [
        { url: "/", label: "Home" },
        { url: `/publication/org/${this.orgId}`, label: "My Publications" },
      ];
    },
  },
  methods: {
    camelToTitleCase,
    ...mapActions(useThemesStore, ["fetchThemes"]),
    loadMore() {
      if (this.hasMoreThemes && !this.isLoadingThemes) {
        this.fetchThemes();
      }
    },
    goToCreateThemes() {
      document.location.href = `/publication/org/${this.orgId}/themes/create`;
    },
    getChangesText(theme) {
      let content = "Has staged changes";
      if (theme.production_version_id === theme.staging_version_id) {
        content = "Up to date with Production";
      }
      return content;
    },
    getIsCloneText(theme) {
      let content = "Clone";
      if (theme.original_theme_id === 0) {
        content = "Original";
      }
      return content;
    },
    getChangesStatus(theme) {
      let status = StatusEnum.ACTIVE;
      if (theme.production_version_id !== theme.staging_version_id) {
        status = StatusEnum.PENDING;
      }
      return status;
    },
  },
};
</script>

<style scoped lang="scss">
:deep(.infinite-scroll-container) {
  width: 100%;
}

:deep(.i-card-header.flex-box) {
  padding-inline: unset;
}

.tag-style {
  border-radius: 5px;
  padding: 4px 8px;
  border: 1px solid #e0e0e0;
}
</style>

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-content[data-v-0bb96509] {
  padding: var(--0bb96509-\\\$getStyles\\.actionItemPadding);
}
.company-users-table[data-v-0bb96509] {
  height: unset !important;
  min-height: unset !important;
  max-height: 600px !important;
}
.organization-user-table[data-v-0bb96509] {
  min-width: 700px;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/pages/settings/Organization.vue","webpack://./Organization.vue"],"names":[],"mappings":"AAEA;EACE,uDAAA;ACDF;ADGA;EACE,wBAAA;EACA,4BAAA;EACA,4BAAA;ACAF;ADEA;EACE,gBAAA;ACCF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.dropdown-content {\n  padding: v-bind(\"$getStyles.actionItemPadding\");\n}\n.company-users-table {\n  height: unset !important;\n  min-height: unset !important;\n  max-height: 600px !important;\n}\n.organization-user-table {\n  min-width: 700px;\n}\n",".dropdown-content {\n  padding: v-bind(\"$getStyles.actionItemPadding\");\n}\n\n.company-users-table {\n  height: unset !important;\n  min-height: unset !important;\n  max-height: 600px !important;\n}\n\n.organization-user-table {\n  min-width: 700px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
